<template>
    <div class="main-panel">
        <SideBar />
        <div class="panel-container">
            <DashNavbar />
            <router-view />
        </div>
    </div>
</template>

<script>
import SideBar from '../Public/SideBar.vue'
import DashNavbar from './DashNavbar.vue'
    export default {
  components: { SideBar, DashNavbar },
  mounted(){
              this.state.token=true
        }
        
    }
</script>

<style lang="scss" scoped>
.main-panel{
    display: flex;
}
.panel-container{
    padding: 10px 120px 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    background: var(--home-background);
}
@media only screen and(max-width:600px){
    .panel-container{
        padding: 10px 80px 10px 10px;
    }
}
</style>