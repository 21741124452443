<template>
<div class="side-container">
    <div :class="openSideBar ? 'open':''" class="sidebar-container">
        <div class="top-content">
            <div @click="$router.push('/')" class="logo">
                <img src="../../assets/Logo.svg" alt="" class="logo-img">
                <span v-if="openSideBar" class="logo-text"> cryptocurrency </span>
            </div>
            <div class="dashboard-items">
                <div @click="$router.push('/advancetrade')" :class="$route.name=='AdvanceTrade'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarp2p.svg')" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> معامله </span>
                </div>
                <div @click="$router.push('/dashboard')" :class="$route.name=='Dashboard'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebardash.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> داشبورد </span>
                </div>
                <div @click="$router.push('/dashboard/wallet')" :class="$route.name=='Wallet'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarwallet.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> کیف پول </span>
                </div>
                <div @click="$router.push('/dashboard/history')" :class="$route.name=='History'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarhistory.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> تاریخچه </span>
                </div>
                <div @click="$router.push('/dashboard/invitefriends')" :class="$route.name=='InviteFriends'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarinvite.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> دعوت از دوستان </span>
                </div>
            </div>
        </div>
        <div class="dashboard-items">
            <div @click="$router.push('/dashboard/account')" :class="$route.name=='Account'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarAccount.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> پروفایل </span>
                </div>
            <div @click="$router.push('/dashboard/settings')" :class="$route.name=='Settings'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarsetting.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> تنظیمات </span>
                </div>
            <div @click="$router.push('/dashboard/notifications')" :class="$route.name=='Notifications'? 'focus-sideitem':''" class="item">
                    <inline-svg :src="require('../../assets/icons/sidebarNotifs.svg')" alt="" class="side-icon" />
                    <span v-if="openSideBar" class="side-title"> پیام ها </span>
                </div>
        </div>
    </div>
    <div @click="openSideBar = !openSideBar" :class="openSideBar ? 'closebtn':'openbtn'">
             <inline-svg class="arrowsvg" :src="require('../../assets/icons/inputarrow.svg')" alt="" />
    </div>
</div>
</template>
<script>
export default {
    name:'SideBar',
    data() {
        return {
            openSideBar:false
        }
    },
}
</script>

<style lang="scss" scoped>
.side-icon{
    fill: #888E95 ;
}
.openbtn{
    width: 40px;
    height: 40px;
    background: var(--sidebar-background);
    position: fixed;
    z-index: 112;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: translate(-70px,30px) rotate(90deg);
    cursor: pointer;
}
.closebtn{
    width: 40px;
    height: 40px;
    background: var(--sidebar-background);
    position: fixed;
    z-index: 112;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: translate(-200px,30px) rotate(-90deg);
    cursor: pointer;
}
.dashboard-items{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
}
.focus-sideitem{
    svg{
        fill: var(--primary-color) ;
    }
    background: var(--sidebar-item-background) !important;
    opacity: 1 !important;
}
.top-content{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.sidebar-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    overflow: scroll;
    position: fixed;
    right: 0;
    width: 100%;
    max-width: 90px;
    padding: 24px;
    border-radius: 4px;
    background: var(--sidebar-background);
    z-index: 111;
}
.logo{
    img{
        height: 44px;
        width: 44px;
    }
    cursor: pointer;
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: start;
    align-items: center;
}
.item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 12px;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    .side-title{
        font-size: clamp(12px,1vw,14px);
        font-weight: 400;
    }
    &:hover{
        opacity: .6;
        background: var(--sidebar-item-background);
    }
}
.open{
    width: 20% !important;
    max-width: 220px !important;
}
@media only screen and(max-width:1000px){
    .open{
    width: 30% !important;
    max-width: 220px !important;
    }
}
@media only screen and(max-width:700px){
    .open{
    width: 40% !important;
    max-width: 220px !important;
}
    .openbtn{
        transform: translate(-40px,30px) rotate(90deg);
    }
}
@media only screen and(max-width:500px){
    .open{
    width: 70% !important;
    max-width: 220px !important;
}
    
}
@media only screen and(max-width:600px){
    .sidebar-container{
        max-width: 65px;
        padding: 10px;
    }
}
</style>